<!--
 * @Author: chenxing
 * @Date: 2021-03-23 10:13:20
 * @LastEditors: Yran
 * @LastEditTime: 2022-01-07 14:30:36
-->
<template>
  <div class="report_center w100 h100">
    <a-tabs v-model:activeKey="activeTabKey" @change="onTabChange" class="filter_tab min_weight50">
      <a-tab-pane key="TT" tab="巨量引擎" force-render>
        <!-- <tt /> -->
      </a-tab-pane>
<!--      <a-tab-pane v-show="false" key="GDT" tab="腾讯广告" force-render>
        &lt;!&ndash; <gdt /> &ndash;&gt;
      </a-tab-pane>-->
      <a-tab-pane key="GDT_V3" tab="腾讯广告3.0" force-render>
        <!-- <gdt /> -->
      </a-tab-pane>
<!--      <a-tab-pane key="KS" tab="磁力引擎" force-render>
        &lt;!&ndash; <ks /> &ndash;&gt;
      </a-tab-pane>-->
      <a-tab-pane key="QC" tab="千川引擎" force-render>
      </a-tab-pane>
      <a-tab-pane key="AQY" tab="爱奇艺" force-render>
      </a-tab-pane>
      <a-tab-pane v-if="true" key="ALL" tab="全渠道" force-render>

      </a-tab-pane>
      <template #tabBarExtraContent>
        <div style="display: flex; align-items: center; margin-right: 3em">
          <label>显示具体数字：</label> <a-switch v-model:checked="amountShowDetail" />
          <span class="color45" style="margin-left: 1em">数据更新时间：{{ refreshTime }}</span>
           <a-button class="ml10 mr10" :disabled="tableLoading" type="primary" @click="getAdvertiserReport">
            刷新
            <template #icon><UndoOutlined /></template>
          </a-button>
          <TempSelect v-if="activeTabKey != 'ALL' " :temp-list="tempList" :active-temp-name="activeTempName" @getTempName="getTempNameValue" @editTemp="editTemp" @deleteTemp="deleteTemp" @add-temp="addTemp" :disabled="tableLoading" />
        </div>
      </template>
    </a-tabs>

    <!-- 通用条件区域 -->
    <a-form layout="horizontal" :model="filterConditions" class="filter-container">
      <a-row>
        <a-col span="4">
          <a-form-item label="日期范围" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-range-picker :placeholder="['开始日期', '结束日期']" v-model:value="defaultRange" :allow-clear="dimension !== 'timeAccumul' && dimension !== 'timeNoAccumul'" separator="到" :format="[dateRangeFormat, dateRangeFormat]" :ranges="dateRanges" :disabled-date="disabledDate" @change="onDateRangeChange" :disabled="tableLoading" />
          </a-form-item>
        </a-col>
        <a-col span="8">
          <a-form-item label="数据维度" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-radio-group button-style="solid" v-model:value="dimension" style="line-height: unset" @change="onDimensionChange" :disabled="tableLoading">
              <a-radio :value="item.value" v-for="(item, index) in dimensionOptions" :key="index">{{ item.text }}</a-radio>
<!--              <a-tooltip placement="topLeft">
                <template #title>
                  <span>该数据维度时间维度请选择一天!</span>
                </template>
                <a-radio v-if="activeTabKey === 'GDT'" :disabled="timeChange" value="timeAccumul" :key="dimensionOptions.length">分时段累计</a-radio>
              </a-tooltip>
              <a-tooltip placement="topLeft">
                <template #title>
                  <span>该数据维度时间维度请选择一天!</span>
                </template>
                <a-radio v-if="activeTabKey === 'GDT'" :disabled="timeChange" value="timeNoAccumul" :key="dimensionOptions.length">分时段不累计</a-radio>
              </a-tooltip>-->
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col span="3">
          <a-form-item label="日期维度" button-style="solid">
            <a-radio-group v-model:value="groupByDate">
              <a-radio-button value="NO">汇总</a-radio-button>
              <a-radio-button value="YES">按日期</a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col span="4">
          <a-form-item label="客户" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select class="select-tagmode" mode="multiple" label-in-value v-model:value="filterConditions.customerNames" placeholder="请选择客户" style="width: 100%" :filter-option="true" :max-tag-count="1" :allow-clear="true" :dropdown-match-select-width="false" @search="onCustomerSearch" @change="onCustomerChange" :disabled="tableLoading">
              <template #notFoundContent>
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </template>
              <a-select-option v-for="c in customerOptions" :label="c.text" :key="c.value">
                {{ c.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="4">
          <a-form-item label="产品" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select class="select-tagmode" mode="multiple" label-in-value v-model:value="filterConditions.projectIds" placeholder="请选择产品" style="width: 100%" option-filter-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" :disabled="tableLoading" @search="onProjectSearch">
              <template #notFoundContent>
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </template>
              <a-select-option v-for="p in projectOptions" :key="p.value" :label="p.text">
                {{ p.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="4">
          <a-form-item label="部门" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-tree-select class="select-tagmode" v-model:value="filterConditions.deptId" :tree-data="departmentOptions" :replace-fields="replaceFields" placeholder="请选择部门" style="width: 100%" @change="onDepartmentChange" tree-node-filter-prop="title" :tree-default-expanded-keys="treeDefaultExpandedKeys" allow-clear show-search :disabled="tableLoading">
              <template #notFoundContent>
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </template>
            </a-tree-select>
          </a-form-item>
        </a-col>
        <a-col span="4">
          <a-form-item label="运营人员" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select class="select-tagmode" mode="multiple" label-in-value v-model:value="filterConditions.operaterIds" placeholder="请选择运营人员" style="width: 100%" option-filter-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :disabled="tableLoading">
              <template #notFoundContent>
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </template>
              <a-select-option v-for="d in operatorOptions" :key="d.value" :label="d.text">
                {{ d.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="8">
          <a-form-item label="代理商" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
            <a-select v-model:value="filterConditions.agentId"  placeholder="请选择代理商主帐户" v-bind="$filterOptions" style="width: 100%" :allow-clear="true">
              <a-select-option :value="accManager.advertiserId" :label="accManager.advertiserId+'-'+accManager.advertiserName " v-for="accManager in managerAccountListTemp" :key="accManager.advertiserId">{{ accManager.advertiserId }}-{{accManager.advertiserName}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col span="4" v-if="activeTabKey == 'ALL' " >
          <a-form-item label="媒体渠道" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select mode="multiple" label-in-value v-model:value="filterConditions.mediaChannels" placeholder="请选择媒体渠道" style="width: 100%" :filter-option="false" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" @search="value => onMediaAccountSearch(value, 'value')" :disabled="tableLoading">
              <a-select-option value="TT">巨量引擎</a-select-option>
              <a-select-option value="TT_2">巨量引擎2.0</a-select-option>
              <a-select-option value="GDT">腾讯广告</a-select-option>
              <a-select-option value="GDT_V3">腾讯广告3.0</a-select-option>
              <a-select-option value="QC">千川引擎</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="4">
          <a-form-item label="业务部门" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select mode="multiple" label-in-value v-model:value="filterConditions.businessDepts" placeholder="请选择业务部门" style="width: 100%" :filter-option="false" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" @search="value => onMediaAccountSearch(value, 'value')" :disabled="tableLoading">
              <a-select-option value="业务一部">业务一部</a-select-option>
              <a-select-option value="业务二部">业务二部</a-select-option>
              <a-select-option value="业务三部">业务三部</a-select-option>
              <a-select-option value="业务五部">业务五部</a-select-option>
              <a-select-option value="业务六部">业务六部</a-select-option>
              <a-select-option value="SDJ">圣达际</a-select-option>
              <a-select-option value="QR">洽尔</a-select-option>
              <a-select-option value="MCN">MCN</a-select-option>
              <a-select-option value="其他">其他</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="4" v-if="activeTabKey === 'TT'">
          <a-form-item label="广告主" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select mode="multiple" label-in-value v-model:value="filterConditions.mdaccountIds" placeholder="广告主ID或名称" style="width: 100%" :filter-option="false" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" @search="value => onMediaAccountSearch(value, 'text')" :disabled="tableLoading">
              <template #notFoundContent>
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </template>
              <a-select-option v-for="d in mediaAccountOptions" :key="d.value" :label="d.text">
                {{ d.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="4" v-else>
          <a-form-item label="广告主" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select mode="multiple" label-in-value v-model:value="filterConditions.mdaccountIds" placeholder="广告主ID或名称" style="width: 100%" :filter-option="false" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" @search="value => onMediaAccountSearch(value, 'value')" :disabled="tableLoading">
              <template #notFoundContent>
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </template>
              <a-select-option v-for="d in mediaAccountOptions" :key="d.value" :label="d.value">
                {{ d.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="4">
          <a-form-item label='多广告主' :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" size="small">
            <a-input v-model:value="filterConditions.mdaIds" placeholder="批量查询ID请用空格隔开" :allow-clear="true"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label='多广告主' :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-input v-model:value="filterConditions.mdaNames" placeholder="批量查询账号名称请用空格隔开" :allow-clear="true"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="4" v-if="activeTabKey == 'ALL'">
          <a-form-item label="端口" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select mode="multiple" label-in-value v-model:value="filterConditions.owners" placeholder="请选择端口" style="width: 100%" :filter-option="false" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" @search="value => onMediaAccountSearch(value, 'value')" :disabled="tableLoading">
              <a-select-option value="LM-本地推">LM-本地推</a-select-option>
              <a-select-option value="拍拍贷今日头条管家">拍拍贷今日头条管家</a-select-option>
              <a-select-option value="LM-星图">LM-星图</a-select-option>
              <a-select-option value="QR">QR</a-select-option>
              <a-select-option value="LM">LM</a-select-option>
              <a-select-option value="SDJ">SDJ</a-select-option>
              <a-select-option value="京东金融头条管家账户">京东金融头条管家账户</a-select-option>
              <a-select-option value="沐瞳科技">沐瞳科技</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <!-- <a-col v-show="activeTabKey == 'GDT' && dimension !== 'timeAccumul'" span="6">
        <a-form-item label="口径" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <caliber @selectItem="onTJKJSelect"></caliber>
        </a-form-item>
      </a-col>
      <a-col v-show="dimension !== 'timeAccumul'" span="6">
        <a-form-item label="指标" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <TempSelect :temp-list="tempList" @getTempName="getTempName" @editTemp="editTemp" @deleteTemp="deleteTemp" @add-temp="addTemp" />
        </a-form-item>
      </a-col> -->
      </a-row>
      <a-row type="flex" justify='center'>
        <a-col span="24" class="pl2em" >
          <a-form-item align='center'>
            <a-space>
              <a-button type="primary" @click="doFilter" :disabled="tableLoading">筛选</a-button>
              <a-button type="primary" @click="doSyncData" :disabled="tableLoading">同步数据</a-button>
              <a-button @click="resetFilter" :disabled="tableLoading">重置筛选</a-button>
              <a-button :loading="excelExporting" :disabled="tableLoading" @click="onExportExcelBtnClick">导出Excel</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- <a-button type="primary" @click="showDrawer" :style="{ textAlign: 'left' }">
    更多条件
  </a-button> -->
    <!-- 已选择筛选条件 -->
    <selected-conditions v-show="conditions.length > 0" :condition-list="conditions" />

    <!-- 趋势图 -->
<!--    <div class="txgg_chart_wrapper trend_wrapper w100 bcg_w" v-if="activeTabKey == 'TT' || activeTabKey == 'GDT'">-->
    <div class="txgg_chart_wrapper trend_wrapper w100 bcg_w">
      <base-chart :chart-option="options" :show-fold="true" title="趋势图">
        <template #filter>
          <a-select label-in-value v-model:value="indexOneKey" @change="getTrendData()" :options="selectOptions" v-bind="$filterOptions"></a-select>
          <a-select v-if="activeTabKey !== 'ALL'" label-in-value v-model:value="indexTwoKey" @change="getTrendData()" :options="selectOptions" v-bind="$filterOptions"></a-select>
        </template>
      </base-chart>
    </div>

    <!-- 表格区域 -->
    <div class="table_wrapper w100">
      <datatable v-if="dimension === 'timeAccumul' || dimension === 'timeNoAccumul'" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" row-key="rowKey" :table-data="reportTabelData" :table-loading="tableLoading" :strip-show="true" :cols-setting="tableColumns" :pagination="tablePagination" :fixed-first-row="dimension !== 'timeAccumul' && dimension !== 'timeNoAccumul'" @table-change="onCustomizeTableChange">
        <template #cost="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.cost).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.cost) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.cost).toLocaleString('en-US') }}
          </template>
        </template>
        <template #viewCount="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.viewCount).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.viewCount) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.viewCount).toLocaleString('en-US') }}
          </template>
        </template>
        <template #clickCount="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.clickCount).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.clickCount) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.clickCount).toLocaleString('en-US') }}
          </template>
        </template>
      </datatable>
      <a-table v-else size="small" :data-source="reportTabelData" :columns="columns" row-key="rowKey" :pagination="tablePagination" :loading="tableLoading" bordered :scroll="{ x: 'max-content', y: tableHeight }" class="ant-table-striped ant-table-fixed-first-row" :row-class-name="(record, index) => (index1 % 2 === 1 ? 'table-striped' : null)" @change="onTableChange">
        <template #channel="{ record }" >
<!--            <span v-if="record.channel == 'TT' "> 巨量引擎 </span>
            <span v-if="record.channel == 'GDT' "> 广点通 </span>
            <span v-if="record.channel == 'QC' "> 巨量千川 </span>-->
          <span > {{record.channel}} </span>
        </template>

        <template #cost="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.cost).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.cost) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.cost).toLocaleString('en-US') }}
          </template>
        </template>
        <template #showNum="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.showNum).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.showNum) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.showNum).toLocaleString('en-US') }}
          </template>
        </template>
        <template #click="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.click).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.click) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.click).toLocaleString('en-US') }}
          </template>
        </template>
        <template #active="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.active).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.active) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.active).toLocaleString('en-US') }}
          </template>
        </template>
        <template #activeCost="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.activeCost).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.activeCost) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.activeCost).toLocaleString('en-US') }}
          </template>
        </template>
        <template #activePayCost="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.activePayCost).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.activePayCost) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.activePayCost).toLocaleString('en-US') }}
          </template>
        </template>
        <template #activePayRate="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.activePayRate).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.activePayRate) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.activePayRate).toLocaleString('en-US') }}
          </template>
        </template>

        <template #payOrderCount="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.payOrderCount).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.payOrderCount) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.payOrderCount).toLocaleString('en-US') }}
          </template>
        </template>

        <template #payOrderAmount="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.payOrderAmount).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.payOrderAmount) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.payOrderAmount).toLocaleString('en-US') }}
          </template>
        </template>

        <template #indirectOrderCreateCount7days="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.indirectOrderCreateCount7days).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.indirectOrderCreateCount7days) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.indirectOrderCreateCount7days).toLocaleString('en-US') }}
          </template>
        </template>

        <template #indirectOrderCreateGmv7days="{ record }">
          <template v-if="!amountShowDetail">
            <a-tooltip>
              <template #title>{{ Number(record.indirectOrderCreateGmv7days).toLocaleString('en-US') }}</template>
              {{ formatAmount(record.indirectOrderCreateGmv7days) }}
            </a-tooltip>
          </template>
          <template v-else>
            {{ Number(record.indirectOrderCreateGmv7days).toLocaleString('en-US') }}
          </template>
        </template>
        
      </a-table>
    </div>
  </div>
  <!-- 自定义列弹窗-->
<!--  <a-modal :visible="false" :mask-closable="false">
    <div>自定义列选择弹窗</div>
  </a-modal>-->
  <!-- 更多筛选条件抽屉 -->
<!--  <a-drawer title="筛选条件" :width="420" :visible="drawerVisible" :body-style="{ paddingBottom: '80px' }" :mask-closable="true" @close="closeDrawer">
    <div :style="{ position: 'absolute', right: 0, bottom: 0, width: '100%', borderTop: '1px solid #e9e9e9', padding: '10px 16px', background: '#fff', textAlign: 'left', zIndex: 1 }">
      <a-button type="primary" @click="onCloseDrawer">确定</a-button>
    </div>
  </a-drawer>-->
  <!-- 自定义列弹窗 -->
  <customize-column :show-check="false" :selected-indicator="selectedIndicator" :fixed-list="fixedList" title-tips="（勾选媒体渠道的指标，请在对应的渠道下查看）" :column-name="editTempName" :menu-list="menuList" :modal-visible="modalVisible" @getDraggableList="getDraggableList" @get-temp-data="getTempData" />
    <!--  <CustomizeColumn :selected-indicator="selectedIndicator" :column-name="activeTemp" :menu-list="menuList" :modal-visible="modalVisible" @getDraggableList="getDraggableList" @get-temp-data="getTempData" />-->
</template>

<script>
/* eslint-disable */
import { UndoOutlined } from '@ant-design/icons-vue';
import { ref, reactive } from 'vue';
import { uuid } from 'vue-uuid';
import { tt, ks, gdt, qc } from './drawer';
import selectedConditions from './selectedConditions.vue';
import caliber from '@/components/Customize/caliber.vue';
import datatable from '@/components/Customize/table.vue';
import CustomizeColumn from '@/components/Customize/customizeColumnModal.vue';
import TempSelect from '@/components/Customize/templateSelect.vue';
import {
  getAdvertiserReportData,
  getMediaAccountListData,
  getOperatorDataByDeptId,
  exportExcel,
  getAggregation,
  getNoAggregation,
  getRefreshTime,
  getDataCenterAllConfigQuota,
  getDataCenterUserConfigTempList,
  getChooseTemp,
  getTrendData
} from '@/api/report';
import { getCustomerDistinctListData } from '@/api/customer/customer.js';
import { getProjectListByCompanyName } from '@/api/base/project.js';
import { getDepartmentTreeData } from '@/api/org/account.js';
import moment from 'moment';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {addChineseUnit, getDocumentScroll, unique} from '@/utils/utils.js';
import { Empty } from 'ant-design-vue';
import {
  postAddTemp,
  postUpdateTemp,
  postDeleteTemp,
  getTableDetail,
  saveNotRestriction
} from '@/api/customize/index.js';
import _ from "lodash";
import BaseChart from '@/views/popularize/components/BaseChart.vue';
import { echartsOptions } from '@/views/material/components/mediaTableColumns';
import { getManagerAccountListData } from '@/api/mediaAccount/mediaAccount';

export default {
  components: {
    BaseChart,
    tt,
    ks,
    gdt,
    qc,
    selectedConditions,
    caliber,
    CustomizeColumn,
    datatable,
    TempSelect,
    UndoOutlined,
  },
  data() {
    return {
      groupByDate:'NO',
      newTableColumnsFiled:null,
      tableSourceColumns: [
      ],
      tablePagination: {
        limit: '30',
        page: '1',
        pageSize: 30,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      },
      filterCondition: {}, //筛选条件值
      // 所有配置项
      menuListSource: [],
      // 选中的自定义列模板
      editTempName: null,
      activeTempName: '默认指标',
      // 固定指标
      fixedList: [],
      selectedRowKeys: [],
      // 模板列表
      tempList: [],
      options: {},
      indexOneKey: { value: 'cost', label: '消耗', key: 'cost' },
      indexTwoKey: { value: 'ctr', label: '点击率', key: 'ctr' },
      selectOptions: [], //趋势图筛选项（非属性指标）,
      managerAccountListTemp:[],
    };
  },
  provide() {
    return {
      getChannel: () => this.activeTabKey,
    };
  },
  setup() {
    const activeConfigType = 'GGBF'; // 口径
    const tableColumns = [];
    const tableHeight = ref(1920);
    // 上次刷新时间
    const refreshTime = ref(moment().format('YYYY-MM-DD hh:mm:ss'));

    // 所有配置项
    const menuList = [];
    // 已选指标项
    const selectedIndicator = [];
    // 选中的自定义列模板
    const activeTemp = null;
    // 编辑的原模板名称
    const oldTempName = null;
    // 自定义指标弹窗
    const modalVisible = ref(false);
    // 模板列表
    const tempList = [];
    const locale = zhCN;
    const dateRangeFormat = 'YYYY-MM-DD';
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    // 替换 treeNode 中 title,value,key,children 字段
    const replaceFields = { children: 'list', title: 'name', key: 'name', value: 'deptId' };
    //默认展开的节点
    const treeDefaultExpandedKeys = [];

    const activeTabKey = ref('TT');
    const channelMap = {
      TT: '巨量引擎',
      // GDT: '腾讯广告',
      GDT_V3: '腾讯广告3.0',
      KS: '磁力引擎',
      QC: '千川引擎',
      AQY: '爱奇艺',
      ALL: '全渠道',
    };
    const amountShowDetail = ref(false);
    const drawerVisible = ref(false);

    // table
    const tableLoading = ref(false);
    const reportTabelData = ref([]);
    const baseColumns = ref([
      { title: '日期', dataIndex: 'statDate', key: 'statDate',  width:150,sorter: true, sortDirections: ['descend', 'ascend'], defaultSortOrder: 'descend', slots: { customRender: 'statDate' } },
      // { title: '总消耗(元)', dataIndex: 'cost', key: 'cost', width: 120, sorter: true, sortDirections: ['descend', 'ascend'], defaultSortOrder: 'descend', slots: { customRender: 'cost' } },
      // { title: '曝光量', dataIndex: 'showNum', key: 'showNum', width: 120, sorter: true, sortDirections: ['descend', 'ascend'], slots: { customRender: 'showNum' } },
      // { title: '点击量', dataIndex: 'click', key: 'click', width: 120, sorter: true, sortDirections: ['descend', 'ascend'], slots: { customRender: 'click' } },
      // { title: 'APP激活总量', dataIndex: 'active', key: 'active', width: 120, sorter: true, sortDirections: ['descend', 'ascend'], slots: { customRender: 'active' } },
      // { title: 'APP激活成本(元)', dataIndex: 'activeCost', key: 'activeCost', width: 130, slots: { customRender: 'activeCost' } },
      // { title: '首次付费成本(元)', dataIndex: 'activePayCost', key: 'activePayCost', width: 130, slots: { customRender: 'activePayCost' } },
      // { title: '首次付费率(%)', dataIndex: 'activePayRate', key: 'activePayRate', width: 130, slots: { customRender: 'activePayRate' } },
      // { title: '首日花费回报率', dataIndex: 'activePayRoi', key: 'activePayRoi', width: 120 },
    ]);
    // 头条报表数据列
    const ttColumns = ref([]);
    // 广点通报表数据列
    const gdtColumns = ref([]);
    const gdtV3Columns = ref([]);
    // 快手报表数据列
    const ksColumns = ref([]);
    // 千川报表数据列
    const qcColumns = ref([]);
    const aqyColumns = ref([]);

    let columnMap = new Map();
    columnMap.set('TT', ttColumns);
    columnMap.set('GDT', gdtColumns);
    columnMap.set('GDT_V3', gdtV3Columns);
    columnMap.set('KS', ksColumns);
    columnMap.set('QC', qcColumns);
    columnMap.set('AQY', aqyColumns);
    columnMap.set('company', [{ title: '客户名称', dataIndex: 'customerOfCompanyName', key: 'customerOfCompanyName', width: 120 }]);
    columnMap.set('mdaAccount', [{ title: '媒体账号ID', dataIndex: 'advertiserId', width: 120, key: 'advertiserId' },{ title: '账号名称', dataIndex: 'advertiserName', width: 120, key: 'advertiserName' }]);
    // columnMap.set('mdaAccount', [{ title: '媒体账号', dataIndex: 'advertiserIdAndName', width: 120, key: 'advertiserIdAndName' }]);
    columnMap.set('project', [{ title: '产品', dataIndex: 'projectName', width: 120, key: 'projectName' }]);
    columnMap.set('dept', [{ title: '部门', dataIndex: 'deptName', width: 120, key: 'deptName' }]);
    columnMap.set('opertor', [{ title: '运营人员', dataIndex: 'operaterName', width: 120, key: 'operaterName' }]);
    // 数据表分页
    const tablePagination = reactive({
      limit: '30',
      page: '1',
      pageSize: 31,
      showTotal: total => `总计${total}条数据`,
      showSizeChanger: true,
      pageSizeOptions: ['11', '21', '31', '51'],
    });
    const tableSorter = reactive({ orderType: 'cost', order: '2' });

    // 筛选条件
    const conditions = ref([
      // { name: '条件122222', value: '条件1' },
    ]);

    const dimension = ref('company');
    const dimensionText = '公司';
    const filterConditions = reactive({
      channel: activeTabKey,
      customerNames: [],
      projectIds: [],
      mdaccountIds: [],
      operaterIds: [],
      startTime: moment().format(dateRangeFormat),
      endTime: moment().format(dateRangeFormat),
      mediaChannels:[],
      businessDepts:[],
      owners:[],
    });

    const dimensionOptions = [
      { text: '客户', value: 'company' },
      { text: '产品', value: 'project' },
      { text: '部门', value: 'dept' },
      { text: '运营人员', value: 'opertor' },
      { text: '媒体账号', value: 'mdaAccount' },
    ];
    const customerOptions = ref([]);
    const customerStorage = [];
    const mediaAccountOptions = ref([]);
    const mediaAccountStorage = [];
    const projectOptions = ref([]);
    const projectStorage = [];
    const operatorOptions = ref([]);
    const operatorStorage = [];
    const departmentOptions = ref([]);

    const dateRanges = {
      昨天: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      最近三天: [moment().subtract(2, 'days'), moment()],
      本周: [moment().startOf('week'), moment.min(moment(), moment().endOf('week'))],
      最近七天: [moment().subtract(6, 'days'), moment()],
      本月: [moment().startOf('month'), moment.min(moment(), moment().endOf('month'))],
      最近三十天: [moment().subtract(29, 'days'), moment()],
      最近九十天: [moment().subtract(89, 'days'), moment()],
    };
    //禁止rangepick选择今天开始的日期
    const disabledDate = current => {
      return current && current > moment().add(1, 'days').startOf('day');
    };
    // rangepicker默认选中日期
    const defaultRange = ref([moment(), moment()]);

    // 获取条件列表数据标识
    const fetching = ref(false);
    const projectFetching = ref(false);
    const operatorFetching = ref(false);

    const excelExporting = ref(false);
    return {
      locale,
      refreshTime,
      tableHeight,
      tableColumns,
      menuList,
      selectedIndicator,
      activeTemp,
      oldTempName,
      modalVisible,
      tempList,
      simpleImage,
      activeConfigType,
      replaceFields, // 替换树属性
      treeDefaultExpandedKeys, // 默认展开的树节点
      activeTabKey, // 选中tab的key
      channelMap,
      amountShowDetail,
      drawerVisible, // 右侧抽屉弹出标志
      tableLoading,
      conditions, // 右侧抽屉已选中的筛选条件
      reportTabelData, //报表数据
      baseColumns,
      columnMap,
      tablePagination,
      tableSorter,
      dimension,
      dimensionText,
      filterConditions, // 筛选条件
      dimensionOptions, // 维度列表
      customerOptions, // 客户列表
      customerStorage,
      mediaAccountOptions, // 媒体账号列表
      mediaAccountStorage,
      projectOptions, // 产品列表
      projectStorage,
      operatorOptions, // 运营人员列表
      operatorStorage,
      departmentOptions, // 部门列表
      fetching,
      projectFetching,
      operatorFetching,
      dateRangeFormat,
      dateRanges,
      disabledDate,
      defaultRange,
      excelExporting,
    };
  },
  mounted() {
    this.getAdvertiserReport();
    this.getCustomerList();
    this.getProjectList();
    this.getMediaAccountList();
    this.getOperatorList();
    this.getDepartmentTree();
    this.getManagerAccountListTemp();
    // this.getTempNamesList();
    // this.getTempName('默认指标');
    // this.getDepartmentList();
    window.onresize = () => {
      return (() => {
        this.tableHeight = getDocumentScroll();
      })();
    };
  },
  computed: {
    columns() {
      console.error("=====computed columns() =原表结构====="+this.activeTabKey)
      let res ;
      if(this.activeTabKey === 'ALL'){
        res = [...this.baseColumns,...this.columnMap.get(this.dimension),
          { title: '渠道', dataIndex: 'channel', key: 'channel',  width:150,sorter: true, sortDirections: ['descend', 'ascend'], defaultSortOrder: 'descend',slots: { customRender: 'channel' } },
          { title: '业务部门', dataIndex: 'businessDept', key: 'businessDept',  width:150,sorter: true, sortDirections: ['descend', 'ascend'], defaultSortOrder: 'descend', slots: { customRender: 'businessDept' } },
          { title: '端口', dataIndex: 'owner', key: 'owner',  width:150,sorter: true, sortDirections: ['descend', 'ascend'], defaultSortOrder: 'descend', slots: { customRender: 'owner' } },
          { title: '消耗', dataIndex: 'cost', key: 'cost',  width:150,sorter: true, sortDirections: ['descend', 'ascend'], defaultSortOrder: 'descend', slots: { customRender: 'cost' } },
        ];
      }else if(this.newTableColumnsFiled){
        res = [...this.baseColumns,...this.columnMap.get(this.dimension),...this.newTableColumnsFiled];
      }else {
        res = [...this.baseColumns ,...this.columnMap.get(this.dimension),...this.columnMap.get(this.activeTabKey).value];
      }
      console.error(res)
      console.error("=====computed columns res   结构=====")

      return res;
    },
    timeChange() {
      let days = this.defaultRange[0] && this.defaultRange[1] && this.defaultRange[1].diff(this.defaultRange[0], 'days');
      return days !== 0;
    },
    //趋势图筛选项（非属性指标）
    selectOptions() {
      const menuList = this.menuListSource;
      let channelData = menuList.find(item => item.key?.includes(this.activeTabKey))?.info || []; //渠道指标
      let shareData = menuList.find(item => item.key?.includes('SHARE_DATA'))?.info || []; //共有指标

      shareData = shareData.filter((item, index) => {
        return !item?.notSupportChannel?.includes(this.activeTabKey) && item?.mediaChannel?.includes(this.activeTabKey);
      });
      channelData = channelData.filter((item, index) => {
        return item?.mediaChannel===this.activeTabKey;
      });
      channelData = channelData.concat(shareData);
      channelData.forEach(item => {
        item.label = item.columnValue;
        item.value = item.columnKey;
      });
      console.log("趋势图指标:",channelData)
      return channelData;
    },
  },
  created() {
    this.getDataCenterAllConfigQuota().then(() => {
      this.onTabChange(this.activeTabKey);
      /*console.info("用户自定义模板列表:{}",this.activeTabKey);
      this.getDataCenterUserConfigTempList();
      // this.mediaChannelChange(this.activeTabKey);
      // // this.mediaChannel = this.activeTabKey;

      if (this.$route.query.md5) {
        this.filterCondition = {
          playTimeValue: [moment().subtract(30, 'days'), moment()],
          startMaterialLaunchDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
          endMaterialLaunchDate: moment().format('YYYY-MM-DD'),
          fileName: '',
        };
        localStorage.removeItem('videoFilterCondition');
        this.filterCondition.md5 = this.$route.query.md5;
        this.filterCondition.mediaChannelList = this.$route.query.channel;
        this.filterCondition.playTimeValue = [this.$route.query.sd ? moment(this.$route.query.sd) : moment(), this.$route.query.ed ? moment(this.$route.query.ed) : moment()];
        this.filterCondition.startMaterialLaunchDate = this.$route.query.sd;
        this.filterCondition.endMaterialLaunchDate = this.$route.query.ed;
      } else {
        this.filterCondition.md5 = '';
        delete this.filterCondition.md5;
      }*/
      // this.getMaterialPageListData(null, true);
      // this.doFilter();
      // this.getAdvertiserReport();
      // this.onTabChange(this.activeTabKey)
    });

  },
  methods: {
    getManagerAccountListTemp(){
      let mediaChannelTemp = this.activeTabKey;
      if( mediaChannelTemp==='GDT_V3') mediaChannelTemp='GDT';
      getManagerAccountListData(mediaChannelTemp).then(res => {
        if (res.code == 0) {
          this.managerAccountListTemp  = res.data;
          console.error(res.data)
        }
      });
    },
    // 获取趋势图数据
    async getTrendData() {
      console.info("趋势图。。。。。。。。。。。。。")
      const params = {
        channel: this.activeTabKey,
        indexOneKey: this.indexOneKey?.value,
        indexTwoKey: this.indexTwoKey?.value,
        startTime: this.filterConditions.startTime,
        endTime: this.filterConditions.endTime,
        dimension: this.dimension,
        customerNames: [],
        projectIds: [],
        operaterIds: [],
        deptId: this.filterConditions.deptId,
        mdaccountIds: [],

        mediaChannels:this.filterConditions.mediaChannels?.map(item => item.value) || [],
        businessDepts:this.filterConditions.businessDepts?.map(item => item.value) || [],
        owners:this.filterConditions.owners?.map(item => item.value) || [],
        agentId: this.filterConditions.agentId,
        mdaIds: this.filterConditions.mdaIds,
        mdaNames: this.filterConditions.mdaNames,
      };
      this.filterConditions.customerNames.forEach(cus => {
        params.customerNames.push(cus.value);
      });
      this.filterConditions.projectIds.forEach(proj => {
        params.projectIds.push(proj.value);
      });
      this.filterConditions.operaterIds.forEach(op => {
        params.operaterIds.push(op.value);
      });
      this.filterConditions.mdaccountIds.forEach(mda => {
        params.mdaccountIds.push(mda.value);
      });
      const res = await getTrendData(params);

      let indexOneData = [];
      let indexTwoData = [];
      res.data?.forEach(item => {
        indexOneData.push([item.date, item.indexOneKey]);
        indexTwoData.push([item.date, item.indexTwoKey]);
      });
      const index1 = this.indexOneKey.label;
      const index2 = this.indexTwoKey.label;
      const data = {
        legend: { data: [index1, index2] },
        series1: { name: index1, data: indexOneData },
        series2: { name: index2, data: indexTwoData },
      };
      const options = echartsOptions.trend(data);
      this.options = options;
    },

    /** 获取选中的模板已选中指标
     * @param {*}
     * @return {*}
     */
    async getChooseTemp(tempName) {
      if ((this.activeTemp && this.activeTemp !== '默认指标') || (tempName && tempName !== '默认指标')) {
        let res = await getChooseTemp(this.activeTabKey,tempName || this.activeTemp);
        this.selectedIndicator = res.list?.[0]?.info;
        this.setColumn(res.list?.[0]?.info);
      }
    },
    /** 设置自定义列
     * @param {*}
     * @return {*}
     */
    setColumn(arr) {
      if (this.activeTabKey=='QC' || this.activeTabKey=='AQY'){
        arr = arr.filter(item => {
          return !item?.notSupportChannel?.includes(this.activeTabKey);
        })
      }
      this.tableSetLoading = true;
      const _this = this;
      console.info("sxh {},setColumn:{}",this.activeTabKey,arr);
      setTimeout(() => {
        _this.tableColumns = _.cloneDeep(_this.tableSourceColumns);
        arr?.forEach(item => {
          const itemChannel = item.oneColumnKeys?.split('_')[0];
          if ((_this.filterCondition.mediaChannelList && _this.filterCondition.mediaChannelList.indexOf(itemChannel) !== -1) || itemChannel === 'SHARE') {
            _this.tableColumns?.push({
              title: item.columnValue.indexOf('率') !== -1 ? item.columnValue + '(%)' : item.columnValue,
              dataIndex: item.columnKey,
              sorter: true,
              width:100,
              sortDirections: ['descend', 'ascend'],
              slots: { customRender: item.columnKey },
            });
          }else {
            _this.tableColumns?.push({
              title: item.columnValue,
              dataIndex: item.columnKey,
              key: item.columnKey,
              width:100,
              sorter: true,
              sortDirections: ['descend', 'ascend'],
              slots: { customRender: item.columnKey },
            });
          }
        });
        _this.tableColumns = unique(_this.tableColumns);
        _this.tableSetLoading = false;
        // this.newTableColumnsFiled = _this.tableColumns;
        console.error(_this.tableColumns)
        console.error("======setColumn(arr) 新表结构=====")
        this.newTableColumnsFiled = _this.tableColumns ;
        // this.columnMap.get(this.activeTabKey).value = _this.tableColumns
      }, 10);
    },
    /** 获取上次刷新时间
     * @param {*}
     * @return {*}
     */
    getRefreshTime() {
      getRefreshTime('SJ_CENTER').then(res => {
        this.refreshTime = res.list;
      });
    },
    async getCustomerList() {
      let res = await getCustomerDistinctListData({});
      this.customerStorage = res.page?.list?.map(item => ({
        text: item.customerOfCompanyName,
        value: item.customerOfCompanyName,
      }));
      this.customerOptions = this.customerStorage.slice(0, 100);
    },
    async getProjectList() {
      this.projectFetching = true;
      let res = await getProjectListByCompanyName({
        companyNames: [],
        // channelType: this.activeTabKey == "GDT_V3" ? "GDT": this.activeTabKey ,
      });

      this.projectStorage = res.page?.list?.map(item => ({
        text: item.projectName,
        value: item.projectCode,
        companyName: item.companyName,
      }));
      this.projectOptions = this.projectStorage.slice(0, 300);
      this.projectFetching = false;
    },
    // async getDepartmentList() {
    //   let res = await getDepartmentData({});
    //   res.deptList.forEach(item => {
    //     this.departmentOptions.push({
    //       text: item.name,
    //       value: item.deptId.toString(),
    //     });
    //   });
    // },
    // 获取部门树形数据
    async getDepartmentTree() {
      let res = await getDepartmentTreeData();
      if (res.code === 0) {
        this.departmentOptions = [res.sysDeptEntity];
        this.treeDefaultExpandedKeys = [res.sysDeptEntity.name];
      }
    },
    async getMediaAccountList() {
      //参数值转换 TT->'巨量引擎'
      if (this.activeTabKey !== 'KS') {
        let res = await getMediaAccountListData({ channelType: this.activeTabKey });
        this.mediaAccountStorage = res.page.list.map(item => ({
          text: item.advertiserName,
          value: item.advertiserId,
        }));
        this.mediaAccountOptions = this.mediaAccountStorage.slice(0, 100);
      }
    },
    async getOperatorList() {
      this.operatorFetching = true;
      let res = await getOperatorDataByDeptId({
        jobs: 'OPERATION',
        deptId: this.filterConditions.deptId,
      });

      this.operatorStorage = res.page.list.map(item => ({
        text: item.userCode,
        value: item.userId,
        deptId: item.deptId,
      }));
      this.operatorOptions = this.operatorStorage;
      this.operatorFetching = false;
    },
    async getAdvertiserReport() {
      console.error("====获取报表数据==getAdvertiserReport=======")
      if (this.tableLoading === true) {
        return;
      }
      this.tableLoading = true;
      let postData = {
        groupByDate:this.groupByDate,
        channel: this.activeTabKey,
        dimension: this.dimension,
        customerNames: [],
        projectIds: [],
        operaterIds: [],
        deptId: this.filterConditions.deptId,
        mdaccountIds: [],
        startTime: this.filterConditions.startTime,
        endTime: this.filterConditions.endTime,
        ...this.tablePagination,
        ...this.tableSorter,
        mediaChannels:this.filterConditions.mediaChannels?.map(item => item.value) || [],
        businessDepts:this.filterConditions.businessDepts?.map(item => item.value) || [],
        owners:this.filterConditions.owners?.map(item => item.value) || [],
        agentId: this.filterConditions.agentId,
        mdaIds: this.filterConditions.mdaIds,
        mdaNames: this.filterConditions.mdaNames,
      };

      this.filterConditions.customerNames.forEach(cus => {
        postData.customerNames.push(cus.value);
      });
      this.filterConditions.projectIds.forEach(proj => {
        postData.projectIds.push(proj.value);
      });
      this.filterConditions.operaterIds.forEach(op => {
        postData.operaterIds.push(op.value);
      });
      this.filterConditions.mdaccountIds.forEach(mda => {
        postData.mdaccountIds.push(mda.value);
      });
      let res;
      try {
        console.info("{}",this.dimension)
        if (this.dimension !== 'timeAccumul' && this.dimension !== 'timeNoAccumul') {
          res = await getAdvertiserReportData(postData);
          this.tablePagination = {
            limit: res.page && (res.page.pageSize - 1).toString(),
            page: (res.page && res.page.currPage) + '',
            total: res.page && res.page.totalCount,
            showTotal: total => `总计${total}条数据`,
            pageSize: res.page.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['11', '21', '31', '51'],
          };
          this.tableColumns = [];
          if (res.code === 0) {
            this.reportTabelData = res.page.list;
            this.tablePagination.current = res.page.currPage;
            this.tablePagination.total = res.page.totalCount;
          }
          // if(this.activeTabKey=='TT' || this.activeTabKey=='GDT')
            this.getTrendData();
        } else {
          res = this.dimension === 'timeAccumul' ? await getAggregation(postData) : await getNoAggregation(postData);
          this.tablePagination = {
            limit: this.tablePagination.pageSize && (this.tablePagination.pageSize - 1).toString(),
            page: this.tablePagination.page + '',
            total: res.records.length,
            pageSize: 24,
            showTotal: total => `总计${total}条数据`,
            showSizeChanger: true,
            pageSizeOptions: ['10', '24'],
          };
          this.reportTabelData = res.records;
        }
      } catch (error) {
        this.tableLoading = false;
      }

      this.reportTabelData.map(item => {
        item.rowKey = uuid.v1();
      });
      this.getRefreshTime();
      this.tableLoading = false;
      this.tableHeight = getDocumentScroll();
    },
    showDrawer() {
      this.drawerVisible = true;
    },
    onCloseDrawer() {
      this.getAdvertiserReport();
      this.closeDrawer();
    },
    closeDrawer() {
      this.drawerVisible = false;
    },
    /*默认渠道指标*/
    async getChanelChooseTemp(chanel) {
      console.info("getChanelChooseTemp:{}",chanel);
      this.activeTemp = '默认指标';
      this.tempName = '默认指标';
      this.activeTempName = '默认指标';
      let res;
      let list;
/*      if ((chanel  && (chanel === 'TT' || chanel === 'TT_2')) ) {
         res = await getChooseTemp(  this.activeTabKey,this.activeTemp || '巨量关键指标' );
      }else if ((chanel  && chanel === 'GDT') ) {
         res = await getChooseTemp( this.activeTabKey,this.activeTemp ||'腾讯关键指标');
      }else if ((chanel  && chanel === 'QC') ) {
         res = await getChooseTemp( this.activeTabKey,  this.activeTemp||'千川关键指标');
      }
      if(res && res.list.length>0){
        this.selectedIndicator = res.list?.[0]?.info;
        list = res.list?.[0]?.info;
      }else{*/
        list = this.menuListSource.filter(item => {
          return item.key === 'SHARE_DATA';
        })?.[0]?.info;
      // }
      // this.setColumn(list);
    },
    //切换渠道
    onTabChange(key) {
      // this.getProjectList();
      console.info("change tab :{}",key)
      this.getDataCenterUserConfigTempList();
      this.getChanelChooseTemp(key)
      if ((this.dimension === 'timeAccumul' || this.dimension === 'timeNoAccumul') && this.activeTabKey !== 'GDT') {
        this.dimension = this.dimensionOptions[0].value;
      }
      //清空表格数据
      this.reportTabelData = [];
      //清空筛选条件
      this.conditions = [];
      //客户跟媒体账号跟渠道相关
      this.getMediaAccountList();
      // this.getCustomerList();
      // this.getProjectList();

      this.mediaChannel = key ;
      this.menuList = this.menuListSource.filter(item => {
        return item.key == 'SHARE_DATA' || item.key.indexOf(key) != -1;
      });
      this.resetFilter();
      this.getManagerAccountListTemp();
    },
    // 切换统计维度
    onDimensionChange(e) {
      this.dimension = e.target.value;
      if (e.target.value !== 'timeAccumul' && e.target.value !== 'timeNoAccumul') {
        this.dimensionText = this.dimensionOptions.find(d => d.value === this.dimension).text;
      } else {
        this.dimensionText = '分时段累计';
      }
      this.tablePagination.current = 1;
      this.tablePagination.page = '1';
      this.getAdvertiserReport();
      this.getTempNameValue(this.activeTemp);
    },
    filterOptions(options, val, type) {
      return options
        .filter(op => {
          let item = op[type] + '';
          return item.indexOf(val.trim()) > -1;
        })
        .slice(0, 100);
    },
    onCustomerSearch(val) {
      let _this = this;
      if (!this.timer) {
        this.timer = setTimeout(function () {
          _this.customerOptions = _this.filterOptions(_this.customerStorage, val, 'text');
          _this.timer = null;
        }, 100);
      }
    },
    onProjectSearch(val) {
      let _this = this;
      if (!this.timer) {
        this.timer = setTimeout(function () {
          _this.projectOptions = _this.filterOptions(_this.projectStorage, val, 'text');
          _this.timer = null;
        }, 100);
      }
    },
    onCustomerChange(val, options) {
      if (options.length > 0) {
        let tempArr = [];
        options.forEach(option => {
          tempArr = tempArr.concat(
            this.projectStorage.filter(proj => {
              return proj.companyName == option.value;
            })
          );
        });

        this.projectOptions = tempArr;
      } else {
        this.filterConditions.projectIds = [];
        this.$nextTick(() => {
          this.projectOptions = this.projectStorage.slice(0, 300);
        });
      }
    },
    onDepartmentChange(val, options) {
      this.filterConditions.deptId = val && val.toString();
      // if (options.length > 0) {
      //   let tempArr = [];
      //   options.forEach(option => {
      //     tempArr = tempArr.concat(
      //       this.operatorStorage.filter(op => {
      //         return op.deptId == option.value;
      //       })
      //     );
      //   });

      //   this.operatorOptions = tempArr;
      // } else {
      //   this.filterConditions.operaterIds = [];
      //   this.operatorOptions = this.operatorStorage;
      // }
      this.getOperatorList();
    },
    onMediaAccountSearch(val, type) {
      let _this = this;
      if (!_this.timer) {
        _this.timer = setTimeout(function () {
          _this.mediaAccountOptions = _this.filterOptions(_this.mediaAccountStorage, val, type);
          _this.timer = null;
        }, 100);
      }
    },
    // 开始时间结束时间选择
    onDateRangeChange(val, dateString) {
      this.filterConditions.startTime = dateString[0];
      this.filterConditions.endTime = dateString[1];
      this.tablePagination.page = '1';
      this.getAdvertiserReport();
    },
    onCustomizeTableChange(pagination) {
      this.tablePagination.pageSize = pagination.pageSize;
      this.tablePagination.limit = pagination.limit;
      this.tablePagination.page = pagination.page;
    },
    // 表格翻页
    onTableChange(pagination, filters, sorter) {
      this.tablePagination.page = pagination.current.toString();
      this.tablePagination.limit = (pagination.pageSize - 1).toString();
      this.tablePagination.pageSize = pagination.pageSize;
      this.tableSorter.orderType = sorter.field;
      this.tableSorter.order = sorter.order == 'ascend' ? '1' : '2';
      this.getAdvertiserReport();
    },
    // 筛选数据
    doFilter() {
      this.tablePagination.current = 1;
      this.tablePagination.page = '1';
      this.getAdvertiserReport();
      this.getTempNameValue(this.activeTemp);
    },
    doSyncData() {
      this.getAdvertiserReport();
    },
    resetFilter() {
      this.filterConditions.channel = this.activeTabKey;
      this.filterConditions.customerNames = [];
      this.filterConditions.projectIds = [];
      this.filterConditions.deptId = null;
      this.filterConditions.mdaccountIds = [];
      this.filterConditions.operaterIds = [];

      this.tablePagination.current = 1;
      this.tablePagination.page = '1';
      this.selectOptions = [];
      this.indexOneKey = { value: 'cost', label: '消耗', key: 'cost' };
      if(this.activeTabKey=='ALL'){
        this.indexTwoKey = { value: '', label: '', key: '' };
      }else{
        this.indexTwoKey = { value: 'ctr', label: '点击率', key: 'ctr' };
      }

      this.getAdvertiserReport();
    },
    formatAmount(val) {
      if (val === 0) {
        val = '';
      }
      return addChineseUnit(val);
    },
    async onExportExcelBtnClick() {
      this.excelExporting = true;
     /* let postData = {
        groupByDate:this.groupByDate,
        channel: this.activeTabKey,
        dimension: this.dimension,
        customerNames: [],
        projectIds: [],
        operaterIds: [],
        deptId: this.filterConditions.deptId,
        mdaccountIds: [],
        startTime: this.filterConditions.startTime,
        endTime: this.filterConditions.endTime,
        ...this.tablePagination,
        ...this.tableSorter,
      };*/

      let postData = {
        groupByDate:this.groupByDate,
        channel: this.activeTabKey,
        dimension: this.dimension,
        customerNames: [],
        projectIds: [],
        operaterIds: [],
        deptId: this.filterConditions.deptId,
        mdaccountIds: [],
        startTime: this.filterConditions.startTime,
        endTime: this.filterConditions.endTime,
        ...this.tablePagination,
        ...this.tableSorter,
        mediaChannels:this.filterConditions.mediaChannels?.map(item => item.value) || [],
        businessDepts:this.filterConditions.businessDepts?.map(item => item.value) || [],
        owners:this.filterConditions.owners?.map(item => item.value) || [],
        agentId: this.filterConditions.agentId,
        mdaIds: this.filterConditions.mdaIds,
        mdaNames: this.filterConditions.mdaNames,
      };

      this.filterConditions.customerNames.forEach(cus => {
        postData.customerNames.push(cus.label.children);
      });
      this.filterConditions.projectIds.forEach(proj => {
        postData.projectIds.push(proj.value);
      });
      this.filterConditions.operaterIds.forEach(op => {
        postData.operaterIds.push(op.value);
      });
      this.filterConditions.mdaccountIds.forEach(mda => {
        postData.mdaccountIds.push(mda.value);
      });

      let res = await exportExcel(postData);
      // application/vnd.ms-excel是转换的文件格式
      let blob = new Blob([res], { type: 'application/vnd.ms-excel' });
      let objectUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = objectUrl;
      link.download = `${this.channelMap[this.activeTabKey]}-${this.dimensionText}`;
      if (this.filterConditions.startTime) link.download += `-${this.filterConditions.startTime}`;
      if (this.filterConditions.endTime) link.download += `至${this.filterConditions.endTime}`;
      link.download += '.xlsx';
      link.click();
      URL.revokeObjectURL(objectUrl);
      this.excelExporting = false;
    },

    // 获取自定义指标菜单列表-数据中心所有指标
    async getDataCenterAllConfigQuota() {
      let res = await getDataCenterAllConfigQuota();
      console.info("获取自定义指标菜单列表-数据中心所有指标：{}",res);
      res.list?.forEach(item => {
        item.info?.forEach(childItem => {
          childItem.customConfigId = childItem.id;
          childItem.info?.forEach(info => {
            info.customConfigId = info.id;
          });
        });
      });
      this.menuList = res.list;
      this.menuListSource = _.cloneDeep(res.list);
    },
    /** 获取排序后的已选指标数组
     * @param {Array} list 已选指标数组
     * @return {*}
     */
    getDraggableList(list) {
      this.selectedIndicator = list;
    },
    /** 关闭弹窗
     * @param {*}
     * @return {*}
     */
    getTempData(tempUserCustoms) {
      console.error("关闭弹窗===@getTempData===")
      if (tempUserCustoms) {
        this.tempUserCustoms = tempUserCustoms;
        let postData = {};
        if (this.operatType === 'add') {
          postData = {
            tempUserCustoms: tempUserCustoms,
          };
          saveNotRestriction(postData).then(res => {
            if (res.code === 0) {
              this.$message.success('新增成功');
              this.modalVisible = false;
              this.getDataCenterUserConfigTempList();
              this.selectedIndicator = [];
            } else {
              this.modalVisible = 'loading';
            }
          });
          // getAllTemp();
        } else if (this.operatType === 'edit') {
          postData = {
            oldTempName: this.activeTemp,
            tempUserCustoms: tempUserCustoms,
          };
          postUpdateTemp(postData).then(res => {
            if (res.code === 0) {
              this.$message.success('修改成功');
              this.modalVisible = false;
              this.selectedIndicator = [];
              this.getTempName();
            } else {
              this.modalVisible = 'loading';
            }
          });
        }
      } else {
        this.modalVisible = false;
        this.selectedIndicator = [];
      }
      // this.modalVisible = false;
    },

    /** 模板下拉变化
     * @param {*} tempName
     * @return {*}
     */
    getTempNameValue(tempName) {
      console.info("获取模板指标：{}",tempName);
      this.activeTemp = tempName;
      if (this.activeTemp && this.activeTemp !== '默认指标' && this.activeTemp !== '默认推荐') {
        this.getChooseTemp();
      } else {
        const list = this.menuListSource.filter(item => {
          return item.key == 'SHARE_DATA';
        })?.[0]?.info;
        this.setColumn(list);
      }
    },

    /** 新增自定义指标
     * @param {*}
     * @return {*}
     */
    addTemp() {
      console.error("============新增自定义指标=====addTemp()=========="+this.modalVisible)
      this.operatType = 'add';
      this.editTempName = null;
      if (this.menuListSource && this.menuListSource.length > 0) {
        this.selectedIndicator = this.menuListSource.filter(item => {
          return item.key == 'SHARE_DATA';
        })?.[0]?.info;
        if (this.activeTabKey=='QC'){
          this.selectedIndicator = this.selectedIndicator.filter(item => {
            return !item?.notSupportChannel?.includes(this.activeTabKey);
          })
        }
        this.modalVisible = true;
      } else {
        this.$message.error('当前口径无可设置指标，请联系后台管理员！');
      }
    },
    /** 点击下拉列表编辑
     * @param {String} tempName 模板名称
     * @return {*}
     */
    async editTemp(tempName) {
      console.info("edit template:{}",tempName);
      this.operatType = 'edit';
      this.editTempName = tempName;
      // let res = await getChooseTemp('GDT', '媒体账户', tempName);
      let res = await getChooseTemp(this.activeTabKey,tempName);
      this.selectedIndicator = res.list && res.list[0] && res.list[0].info;
      this.activeTemp = res.list && res.list[0] && res.list[0].name;
      this.modalVisible = true;
    },
    /** 获取模板名称
     * @param {*}
     * @return {*}
     */
    async getDataCenterUserConfigTempList() {
      getDataCenterUserConfigTempList('DATA_CENTER',this.activeTabKey).then(res => {
        console.info("用户自定义模板列表:{}",res);
        if (res.code == 0) {
          this.tempList = res.list;
          this.getTempNameValue(this.activeTemp);
        }
      });
    },
    /** 模板下拉列表改变
     * @param {String} tempName 模板名称
     * @return {*}
     */
    /*async getTempName(tempName) {
      this.activeTemp = tempName;
      this.getTableDetail();
      let res = await getTempNameDetail('GDT', '媒体账户', tempName);
      this.tableColumns = [];
      res.list &&
        res.list[0] &&
        res.list[0].info.forEach(item => {
          this.tableColumns.push({
            title: item.columnValue,
            dataIndex: item.columnKey,
          });
        });
    },*/
    /** 点击下拉列表删除
     * @param {String} tempName 模板名称
     * @return {*}
     */
    deleteTemp(tempName) {
      this.operatType = 'delete';
      postDeleteTemp([tempName]).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功');
          this.getDataCenterUserConfigTempList();
        }
      });
    },
    /** 获取表格详情
     * @param {Object} postParams 筛选参数
     * @return {*}
     */
    getTableDetail(postParams) {
      let postData = {
        configKey: this.activeTabKey,
        mediaChannel: 'GDT',
        tempName: this.activeTemp,
        moduleName: '媒体账户',
        page: '1',
        limit: '10',
        ...postParams,
      };
      getTableDetail(postData).then(res => {
        this.tableData = res.page && res.page.records;
      });
    },
    onSelectChange(selectKeys, selectedRows) {
      this.selectedRowKeys = selectKeys;
    },
  }
};
</script>
<style lang="less" scoped>
.report_center {
  display: flex;
  flex-direction: column;
}
.filter_tab {
  margin-top: -16px;
  background: #fff;
  margin-top: 0;
  border-radius: 8px 8px 0 0;
}
.filter-container {
  :deep(.ant-form-item-label) {
    line-height: 40px;
  }
}

.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
.ant-table-fixed-first-row :deep(table) tbody tr:nth-child(1) td {
  background: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}
.min_weight40{
  min-height: 30px;
}
.min_weight50{
  min-height: 50px;
}
</style>
<style lang="less">
.select-tagmode {
  .ant-select-selector {
    flex-wrap: nowrap;
    .ant-select-selection-item {
      flex: 0 1 auto;
      min-width: 4em;
    }
  }
}
</style>