/*
 * @Author: Yran
 * @Date: 2021-06-04 11:37:20
 * @LastEditors: Yran
 * @LastEditTime: 2021-07-12 18:51:39
 */
import axios from '@/utils/axios';

const apiUrl = {
    /**********************自定义列信息**************************************/
    getAllTempList: '/temp/tempUserCustom/getConfigTypeAndTempNames',
    getTempNames: '/temp/tempCustomConfig/getByMediaChannelAndModuleName',
    getTempNameDetail: '/temp/tempUserCustom/getTempNames',
    saveAddTemp: '/temp/tempUserCustom/save',
    saveNotRestriction: '/temp/tempUserCustom/saveNotRestriction',
    saveUpdateTemp: '/temp/tempUserCustom/update',
    deleteTemp: '/temp/tempUserCustom/deleteByTempName',
    getTableDetail: '/promote/promoteAnalysis/getMedias',
    getDataCenterAllTemp: '/temp/tempCustomConfig/getByConfigKey/DATA_CENTER',
};



//获取自定义列表数据的方法
export function getAllTempList(configType, mediaChannel) {
    return axios.get(apiUrl.getAllTempList + `/${configType}/${mediaChannel}`);
}
export function getAllTempListForDataCenter() {
    return axios.get(apiUrl.getDataCenterAllTemp);
}
//获取所有自定义模板列表数据的方法
/*export function getTempNamesList(configType, mediaChannel, moduleName) {
    return axios.get(apiUrl.getTempNames + `/${configType}/${mediaChannel}/${moduleName}`);
}*/
export function getTempNamesList() {
    return axios.get(apiUrl.getDataCenterAllTemp);
}
//获取自定义模板详细数据的方法
export function getTempNameDetail(mediaChannel, moduleName, tempName) {
    return axios.get(apiUrl.getTempNameDetail + `/${mediaChannel}/${moduleName}/${tempName}`);
}

//新增自定义列表数据的方法
export function postAddTemp(params) {
    return axios.post(apiUrl.saveAddTemp, params);
}
//新增自定义列表数据的方法
export function saveNotRestriction(params) {
    return axios.post(apiUrl.saveNotRestriction, params);
}
//编辑自定义列表数据的方法
export function postUpdateTemp(params) {
    return axios.post(apiUrl.saveUpdateTemp, params);
}
//删除自定义列表模板的方法
export function postDeleteTemp(tempName) {
    return axios.post(apiUrl.deleteTemp, tempName);
}
//获取自定义模板下的表格数据的方法
export function getTableDetail(params) {
    // return null;
    return axios.post(apiUrl.getTableDetail, params);
}

